import { MesoKitContext } from "@tigris/mesokit";
import { toast } from "sonner";
import { ApiContextProvider } from "../src/contexts/ApiContextProvider";
import { OnboardingContextProvider } from "../src/contexts/OnboardingContextProvider";
import { RouterContextProvider } from "../src/contexts/RouterContextProvider";
import { SardineRiskSession } from "../src/contexts/SardineRiskSession";
import { DevControls } from "../src/dev/DevControls";
import { Layout } from "../src/Layout";
import {
  OnboardingAppRenderContext,
  OnboardingConfiguration,
  OnboardingContextValue,
  Routes,
} from "../src/types";
import { routes } from "../src/routes";
import { MesoPosthog, MesoSentry } from "@tigris/common";
import { getNextOnboardingStep } from "../src/utils/getNextOnboardingStep";

type OnboardingAppProps = Required<OnboardingConfiguration> & {
  onReturnToTransfer: OnboardingContextValue["returnToTransfer"];
  telemetry: {
    Sentry: MesoSentry;
    Posthog: MesoPosthog;
  };
};

export const OnboardingApp = ({
  network,
  partner,
  session,
  walletAddress,
  telemetry,
  onReturnToTransfer,
  supportedPaymentMethods,
  profileStatus,
}: OnboardingAppProps) => {
  const next = getNextOnboardingStep(
    Routes.Root,
    profileStatus,
    supportedPaymentMethods,
  );

  let initialRoute = { pathname: Routes.Root };
  if (next.isOk()) {
    initialRoute = { pathname: next.value.pathname };
  }

  return (
    <RouterContextProvider
      routes={routes}
      initialRoute={initialRoute}
      renderContext={OnboardingAppRenderContext.EMBEDDED_IN_TRANSFER}
    >
      <MesoKitContext.Provider
        value={{
          sentry: telemetry.Sentry,
          posthog: telemetry.Posthog,
          toast,
        }}
      >
        <ApiContextProvider providedSession={session}>
          <OnboardingContextProvider
            network={network}
            partner={partner}
            walletAddress={walletAddress}
            onReturnToTransfer={onReturnToTransfer}
            supportedPaymentMethods={supportedPaymentMethods}
            profileStatus={profileStatus}
            renderContext={OnboardingAppRenderContext.EMBEDDED_IN_TRANSFER}
          >
            <SardineRiskSession flow="onboarding">
              <Layout />
            </SardineRiskSession>
          </OnboardingContextProvider>
          <DevControls />
        </ApiContextProvider>
      </MesoKitContext.Provider>
    </RouterContextProvider>
  );
};
