import { UserFragment } from "@src/generated/sdk";
import { fiatInstrumentFactory } from "./fiatInstrument";
import { walletInstrumentFactory } from "./walletInstrument";
import * as Factory from "factory.ts";
import { User, UserStatus } from "@src/types";
import { MESO_MAX_AMOUNT, MESO_MIN_AMOUNT } from "@src/utils/constants";

export const USER_WALLET_ADDRESS = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";

export const userFactory = Factory.Sync.makeFactory<UserFragment>({
  __typename: "User",
  status: UserStatus.NEW,
  id: "userId",
  email: "john.smith@meso.network",
  theme: "default",
  fiatInstruments: {
    __typename: "FiatInstruments",
    collection: [fiatInstrumentFactory.build()],
  },
  walletInstruments: {
    __typename: "WalletInstruments",
    collection: [walletInstrumentFactory.build()],
  },
  depositAddressInstruments: {
    __typename: "DepositAddressInstruments",
    collection: [],
  },

  limits: {
    transferMin: { amount: `${MESO_MIN_AMOUNT}`, currency: "USD" },
    transferMax: { amount: `${MESO_MAX_AMOUNT}`, currency: "USD" },
    monthlyMax: { amount: "1000.00", currency: "USD" },
    monthlyCashInUsed: { amount: "560.00", currency: "USD" },
    monthlyCashInAvailable: { amount: "440.00", currency: "USD" },
  },
});

export const userWithExistingWalletFactory = userFactory.extend({
  status: UserStatus.ACTIVE,
  walletInstruments: {
    __typename: "WalletInstruments",
    collection: [
      walletInstrumentFactory.build({
        address: USER_WALLET_ADDRESS,
      }),
    ],
  },
});

// The `user` model on context differs slightly from the `UserFragment` from the API
export const appContextUserFactory = Factory.Sync.makeFactory<User>({
  id: "u_001",
  status: UserStatus.NEW,
  email: "john.smith@meso.network",
  fiatInstruments: {
    __typename: "FiatInstruments",
    collection: [fiatInstrumentFactory.build()],
  },
  walletInstruments: {
    __typename: "WalletInstruments",
    collection: [walletInstrumentFactory.build()],
  },
  phone: "815-838-2294",
  theme: "default",
});

export const defaultAppContextUserFactory = Factory.Sync.makeFactory<User>({
  theme: "default",
  phone: "(•••) •••-••••",
});
