import { Routes } from "@src/utils/constants";
import { Result, err, ok } from "neverthrow";
import { ErrorMessages } from "@tigris/mesokit";
import { LoginFragment, TransferKind } from "@src/generated/sdk";
import { InternalLinkPath, UserStatus } from "@src/types";
import { NavigateOptions } from "react-router-dom";

type ProfileStatus = LoginFragment["profileStatus"];

type NextRoute = {
  to: InternalLinkPath;
  options?: NavigateOptions;
};

export const nextAuthStep = ({
  profileStatus,
  needsTwoFactor,
  transferKind,
  userStatus,
  search,
}: {
  profileStatus: ProfileStatus;
  needsTwoFactor: boolean;
  transferKind: TransferKind;
  userStatus?: UserStatus;
  search: string;
}): Result<NextRoute, string> => {
  const hasCompletedOnboardingSteps =
    Object.values(profileStatus).every(Boolean);

  switch (userStatus) {
    // This should be sufficient to skip looking at the `account` property and allow previously onboarded users to proceed with their transfer
    case UserStatus.ACTIVE: {
      if (needsTwoFactor) {
        return ok({ to: { pathname: Routes.TransferSheet2Fa, search } });
      } else if (transferKind === TransferKind.CASH_IN) {
        return ok({ to: { pathname: Routes.TransferSheet, search } });
      } else {
        return ok({
          to: { pathname: Routes.TransferSheetDepositAddress, search },
        });
      }
    }

    case UserStatus.NEW: {
      if (hasCompletedOnboardingSteps && !needsTwoFactor) {
        return ok({ to: { pathname: Routes.ActivateUser, search } });
      } else if (profileStatus.phoneVerified && needsTwoFactor) {
        return ok({
          to: { pathname: Routes.TransferSheet2Fa, search },
          options: { state: { profileStatus } },
        });
      } else if (!hasCompletedOnboardingSteps) {
        return ok({ to: { pathname: Routes.Onboarding, search } });
      } else {
        return err(ErrorMessages.onboardingSteps.UNABLE_TO_DETERMINE_NEXT_STEP);
      }
    }

    case UserStatus.PROVISIONAL: {
      if (hasCompletedOnboardingSteps) {
        return ok({ to: { pathname: Routes.ActivateUser, search } });
      }
    }

    default:
      return ok({ to: { pathname: Routes.TransferUnavailable, search } });
  }
};
