import { AppContext } from "@src/contexts/AppContextProvider";
import { CashOutQuote } from "@src/generated/sdk";
import { Routes } from "@src/utils/constants";
import { OutletContext, UseQuoteHook } from "@src/types";
import { motion } from "framer-motion";
import { spring } from "@src/utils/animation";
import {
  DEFAULT_THEME,
  Logo,
  Text,
  Button,
  ThemeName,
  TokenIcon,
} from "@tigris/mesokit";
import { toast } from "sonner";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { truncateAddress } from "@tigris/common";
import { useOnboarding } from "@src/hooks/useOnboarding";

const TOAST_ID = "TransferSheetDepositAddress";
const isAuthorizedCashOutQuote = (quote: UseQuoteHook["quote"]): boolean => {
  return !!(quote && (quote as CashOutQuote).depositAddress);
};

export const TransferSheetDepositAddress = () => {
  const {
    useQuote: { quote },
  } = useOutletContext<OutletContext>();
  const cashOutQuote = quote as CashOutQuote;
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    api: { resolveUser },
    session,
    user,
    configuration: { destinationAsset, sourceAsset, network },
    updateUser,
  } = useContext(AppContext);
  const { supportedPaymentMethods } = useOnboarding();

  useEffect(() => {
    if (!isAuthorizedCashOutQuote(quote)) {
      const lookupUser = async () => {
        const userResult = await resolveUser();

        if (userResult.isErr()) {
          toast.error(userResult.error, { id: TOAST_ID });
          return;
        }

        const updatedUser = userResult.value;

        if (
          updatedUser.fiatInstruments?.__typename === "FiatInstruments" &&
          updatedUser.walletInstruments?.__typename === "WalletInstruments" &&
          updatedUser.depositAddressInstruments?.__typename ===
            "DepositAddressInstruments"
        ) {
          updateUser(
            {
              ...updatedUser,
              theme: updatedUser.theme as ThemeName,
              fiatInstruments: updatedUser.fiatInstruments,
              walletInstruments: updatedUser.walletInstruments,
              depositAddressInstruments: updatedUser.depositAddressInstruments,
            },
            supportedPaymentMethods,
          );
        }
      };
      lookupUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <motion.div
      key="TransferSheet:depositAddress"
      data-testid="TransferSheetDepositAddress"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, x: 0, transition: { ...spring } }}
      exit={{ opacity: 0, transition: { ...spring } }}
      className="flex h-full w-full flex-col justify-between overflow-hidden pb-4"
    >
      <motion.div
        key="DepositAddress"
        initial={{ scale: 0.9, y: 16, opacity: 0, height: "180px" }}
        animate={{ scale: 1, opacity: 1, y: 0, transition: { delay: 0.4 } }}
        exit={{ opacity: 0, transition: { delay: 0.2 } }}
        className="card-hero relative flex flex-col items-center justify-center gap-1 overflow-hidden"
      >
        <div className="flex h-full items-center overflow-visible p-4">
          <div className="flex justify-center">
            <div className="relative z-10 flex h-full items-center justify-center gap-3 rounded-full bg-white/50 p-3 pr-6 shadow-lg backdrop-blur-sm dark:bg-neutral-700/50">
              <Logo size="md" showText={false} />
              <div className="flex flex-col items-start">
                {cashOutQuote?.depositAddress ? (
                  <Text className="font-bold">Deposit account created</Text>
                ) : (
                  <Text className="font-bold opacity-50">
                    Creating deposit account
                  </Text>
                )}
                {cashOutQuote?.depositAddress && (
                  <div className="flex items-center justify-center gap-1">
                    <TokenIcon size="sm" token={sourceAsset!} />
                    <Text className="font-mono">
                      {truncateAddress(cashOutQuote.depositAddress, network)}
                    </Text>
                  </div>
                )}
              </div>
            </div>
            {cashOutQuote?.depositAddress && (
              <motion.div
                key="chromatic-background"
                initial={{ opacity: 0, y: "-50%", scale: 0.5 }}
                animate={{
                  opacity: 1,
                  y: "-50%",
                  scale: 1,
                  transition: { duration: 0.5, ...spring },
                }}
                exit={{ opacity: 0, scale: 0.7 }}
                className="chromatic-background-wrapper"
              >
                <motion.div
                  initial={{
                    rotate: 0,
                  }}
                  animate={{
                    rotate: 360,
                    transition: {
                      repeat: Infinity,
                      duration: 6,
                      repeatType: "loop",
                      ease: "linear",
                    },
                  }}
                  className="chromatic-background-inner"
                />
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
      <motion.div
        key="TransferSheet:depositAddressExplainer"
        className="flex flex-col items-center justify-center gap-1"
      >
        <Text className="relative z-10 text-left text-lg font-bold">
          Safe and Simple Cash Out
        </Text>
        <div className="text-fg-subtle text-center text-xs">
          <div>{`Authorize a ${sourceAsset} transfer to your deposit account.`}</div>
          <div>{`Meso converts and deposits ${destinationAsset} into your bank.`}</div>
        </div>
        <a
          href="https://support.meso.network/"
          target="_blank"
          rel="noreferrer"
          className="text-fg-subtle mt-2 text-xs font-semibold leading-none"
        >
          Learn more about cash out →
        </a>
      </motion.div>
      <Button
        containerClassName="px-4"
        disabled={
          !session ||
          !isAuthorizedCashOutQuote(quote) ||
          !(quote as CashOutQuote)?.depositAddress
        }
        onClick={() => navigate({ pathname: Routes.TransferSheet, search })}
        isLoading={!quote}
        themeOverride={
          user.theme === DEFAULT_THEME ? undefined : "transfer-button"
        }
      >
        Review Order
      </Button>
    </motion.div>
  );
};
